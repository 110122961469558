import React, { useState, useEffect } from "react";
import FetchApi from "../../../AdminSide/Pages/Login/FetchApi";
import { BASEURL } from "../../../AdminSide/Components/Constants";
import { ClientController } from "../../ClientController/ClientController";
import aboutUs1 from "../../utilities/images/about-us-section-1.png";
import doodleImg1 from "../../utilities/images/doodle-img.png";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./About.css";

const About = (props) => {
  const [ourTeam, setOurTeam] = useState([]);

  const getTeam = async () => {
    return await FetchApi(BASEURL + "get_team.php", "GET");
  };

  useEffect(() => {
    getTeam().then((res) => {
      setOurTeam(res && res.data);
    });
  }, []);

  const responsivePoints = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    992: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  };

  return (
    <ClientController pathname={props.location.pathname}>
      <div className="about_and_team-sections">
        <div className="about-us-section container-fluid">
          <div data-aos="fade-left" className="about-us-section__doodle">
            <img src={doodleImg1} alt="" />
          </div>
          <div className="row">
            <div
              data-aos="fade-right"
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  about-section-left"
            >
              <img src={aboutUs1} alt="Biz haqimizda" />
            </div>
            <div
              data-aos="zoom-in-left"
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 about-section-right"
            >
              <h1>Biz haqimizda</h1>
              <p>
                Jamoamiz 1996 yilda o‘z faoliyatini boshlab, 2020 yilda yangi
                IMSOFT GROUP nomi ostida faoliyat olib bormoqda. Ushbu yillar
                mobaynida bizning mutaxassislarimiz 1C dasturiy taʼminotiga
                texnik xizmat ko‘rsatish hamda mobil, web, kompyuter dasturiy
                taʼminotlar, sozlash va amalga oshirish bo‘yicha xizmatlarning
                to‘liq spektrini amalga oshirmoqdalar.
                <br />
                <br />
                IMSOFT GROUP dasturiy mahsulotlarini taklif qiladi, dasturiy
                taʼminotni qo‘llab-quvvatlaydi, shuningdek mobil ilovalar, web
                saytlar va turli sohalarda kompyuterlar uchun dasturlar hamda
                korxonalarda 1C: Enterprise platformasida individual loyihalarni
                ishlab chiqadi va bizning yechimlarimiz mijoz tomonidan talab
                qilinadigan ishonchlilik va samaradorlikni taʼminlaydi.
                <br />
                <br />
                Kompaniyamiz har qanday sohadagi korxonalarni biznesini
                avtomatlashtirish bo‘yicha keng ko‘lamli xizmatlarni taqdim
                etadi.
              </p>
            </div>
          </div>
        </div>

        <div className="our-team-section">
          <h3 data-aos="flip-up" className="our-team-section__title">
            Bizning jamoa
          </h3>
          <div
            data-aos="zoom-in-down"
            data-aos-duration="1300"
            className="our-team-section__carousel"
          >
            <ReactOwlCarousel
              className="owl-theme"
              responsive={responsivePoints}
              loop
              rtlClass="lala"
              dotsEach
              autoplay
              margin={1}
            >
              {" "}
              {ourTeam &&
                props?.ourTeamData?.map((team) => (
                  <div className="team-card text-center" key={team.id}>
                    <div className="team-card__img">
                      <img
                        src={BASEURL + team.image}
                        alt="jamoamiz azosi"
                        className="box-img"
                      />
                    </div>
                    <p className="team-card__name">
                      <b>{team.fullName.toUpperCase()}</b>
                    </p>
                    <p className="team-card__profession">
                      <b>{team.profession}</b>
                    </p>
                    <button className="team-card__btn">Batafsil</button>
                  </div>
                ))}
            </ReactOwlCarousel>
          </div>
          <div className="our-team-section__foot">
            Agar sizxam IT soxasiga qiziqsangiz <br />
            biz o’z jamoamizda sizni kutmoqdamiz!
          </div>
        </div>
      </div>
    </ClientController>
  );
};

export default About;
