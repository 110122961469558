import { ClientController } from "../../ClientController/ClientController";
import "./services.scss";
import { useEffect, useState } from "react";
import img1 from "../../utilities/images/gallery1.jpg";
import img2 from "../../utilities/images/gallery2.jpg";
import img3 from "../../utilities/images/gallery3.jpg";
import img4 from "../../utilities/images/gallery4.jpg";
import img5 from "../../utilities/images/gallery5.jpg";
import img6 from "../../utilities/images/gallery6.jpg";
import img7 from "../../utilities/images/gallery7.jpg";
import img8 from "../../utilities/images/gallery8.jpg";
import img9 from "../../utilities/images/gallery9.jpg";
import img10 from "../../utilities/images/gallery10.jpg";
import FetchApi from "../../../AdminSide/Pages/Login/FetchApi";
import { BASEURL } from "../../../AdminSide/Components/Constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const Services = (props) => {
  const tabs = [
    "Grafik dizayn",
    "Android",
    "IOS",
    "Desktop",
    "Web",
    "1C platforma",
  ];

  const tabContent = [
    "Grafik Dizayn - Lremssdsd ipsum temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi",
    "Android - Lrem ipsum temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi",
    "IOS - Lrem ipsum temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi",
    "DESKTOP - Lrem ipsum temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi",
    "WEb - Lrem ipsum temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi",
    "1C platorm - Lrem ipsum temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi",
  ];

  const gallery = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img8,
    img3,
  ];

  const [activeTab, setActiveTab] = useState(0);
  const [services, setServices] = useState([]);
  const [images, setImages] = useState([]);
  const [text, setText] = useState("");

  const getServices = async () => {
    return await FetchApi(BASEURL + "get_services.php", "GET");
  };

  useEffect(() => {
    getServices().then((res) => {
      setServices(res && res.data);
      setImages(res && res.data[0].image);
      setText(res && res.data[0].text);
      setActiveTab(0);
    });
  }, []);

  const changeTab = (service, i) => {
    setImages(service.image);
    setText(service.text);
    setActiveTab(i);
  };

  console.log(services);

  return (
    <ClientController pathname={props.location.pathname}>
      <div className="page-services container-fluid">
        <div className="row">
          <div data-aos="fade-right" data-aos-duration="1300" className="col-9">
            <ul className="page-services-nav">
              {services?.map((service, i) => (
                <li className="nav-item">
                  <button
                    className={
                      activeTab !== i ? "nav-link" : "nav-link active-link"
                    }
                    onClick={() => changeTab(service, i)}
                  >
                    {service.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/* <div data-aos="fade-left" data-aos-duration="1300" className="col-2">
              <button className="page-services__btn">
                Buyurtna berish
                <span className="page-services__btn__arrow" />
              </button>
            </div> */}
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="1500"
          className="page-services__gallery row"
        >
          {images?.map((img, i) => {
            return (
              <div className="col-2" key={i}>
                <a>
                  <LazyLoadImage
                    src={BASEURL + img}
                    className="img"
                    effect="blur"
                    delayTime={2500}
                    style={{ width: "100%", height: "100%" }}
                  />
                </a>
              </div>
            );
          })}
        </div>
        <div data-aos="flip-down" className="page-services__tab-content">
          <div className="tab-text">{text}</div>
        </div>
        <div className="page-services-foot">
          <button className="page-services__btn">
            Buyurtna berish
            <span className="page-services__btn__arrow" />
          </button>
        </div>
      </div>
    </ClientController>
  );
};

export default Services;
