const mainLinks = () => {
    return ([
        {
            title: "Asosiy", path: "", icon: " icon-home", amount: 12,
        },
        {
            title: "Loyhalar", path: "/projects", icon: " icon-project"
        },
        {
            title: "Kompaniyalar", path: "/companies", icon: " icon-trusted-us"
        },
        {
            title: "Xizmatlar", path: "/services", icon: " icon-service"
        },
        // {title: "Kurs", path: "/admin/courses", icon: " icon-courses"},
        // {title: "Xabarlar", path: "/admin/messages", icon: " icon-email"},
    ]);
}
export default mainLinks();