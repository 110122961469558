import React, {useEffect, useState} from 'react';
import {AdminController} from "../../AdminController/AdminController";
import {CloseIcon, DeleteIcon, EditIcon} from "../../Components/icons/icons";
import FetchApi from "../Login/FetchApi";
import {BASEURL} from "../../Components/Constants";

export const AdminServices = (props) => {
    const [mainData, setMainData] = useState([]);
    const [isOpenModal, setModal] = useState(false);
    const [isOpenDeleteModal, setDeleteModal] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [preview, setPreview] = useState([]);

    const getAllData = async () => {
        return await FetchApi(BASEURL + "get_services.php", "GET");
    }

    useEffect(() => {
        getAllData().then(r => {
            setMainData(r && r.data);
        });
    }, [modalData, deleteItem])

    useEffect(() => {
        setSelectedFiles([])
        let inputImages = document.getElementById("images");
        if (inputImages) {
            inputImages.value = null;
        }
    }, [isOpenModal])

    const deleteOne = async () => {
        toggleDeleteModal(null)
        let obj = {
            Id: deleteItem.Id
        }
        let res = await FetchApi(BASEURL + "del_services.php", "POST", obj);
        await getAllData()
    }

    const toggleDeleteModal = (item) => {
        setDeleteItem(item);
        setDeleteModal(!isOpenDeleteModal);
    }

    const edit = (item) => {
        setModal(true);
        setModalData(item);
        let imageBox = document.getElementById("images-box");
        if (item?.image.length === 0) {
            imageBox.textContent = "Rasm tanlang";
        } else {
            imageBox.textContent = item.image.length + " ta"
        }

        let el = document.getElementById("name");
        let el2 = document.getElementById("text");
        if (el) {
            el.value = item.name;
        }
        if (el2) {
            el2.value = item.text;
        }
    }

    const toggleModal = (bool) => {
        setModalData(false)
        setModal(bool)
        let el = document.getElementById("name");
        let el2 = document.getElementById("text");
        if (!modalData) {
            if (el) {
                el.value = null;
            }
            if (el) {
                el2.value = null;
            }
        }
    }

    const sendForm = async () => {
        let name = document.getElementById("name");
        let text = document.getElementById("text");
        let imagesInput = document.getElementById("images");

        if (name.value !== "") {
            let imageName = await sendImage();
            setSelectedFiles([])
            imagesInput.value = "";

            let obj = {
                name: name && name.value,
                text: text && text.value,
                image: imageName,
            }
            console.log(obj)
            console.log(modalData)
            toggleModal(false)
            if (modalData) {
                let arr = [];
                for (const img of modalData.image) {
                    if (img.includes("rasm")) {
                        arr.push(img);
                    }
                }
                obj = {...obj, image: [...arr, ...obj.image]};
                await FetchApi(BASEURL + "del_services.php", "POST", {Id: modalData.Id});
            }
            let response = await FetchApi(BASEURL + "add_services.php", "POST", obj);
            console.log(response);
            await getAllData().then(r => {
                setMainData(r && r.data);
            });
        }
    }

    const sendImage = async () => {
        let imageNames = [];
        let formData = new FormData();
        let tartib = 1;

        for (const img of selectedFiles) {
            formData.append("image", img);
            formData.append("kod", tartib);
            tartib = tartib + 1;
            await FetchApi(BASEURL + "insert_image.php", "POST", formData).then(res => {
                if (res) {
                    imageNames.push(res.data);
                }
            });

        }
        return imageNames;
    }


    useEffect(() => {
        if (!selectedFiles) {
            setPreview(undefined)
            return
        }
        let arr = [];
        selectedFiles.map(file => {
            arr.push(URL.createObjectURL(file));
        })

        setPreview(arr)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(arr[1])
    }, [selectedFiles])

    const hiddenFileInput = React.useRef(null);
    const uploadFile = () => {
        hiddenFileInput.current.click();
    }
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFiles(undefined);
            return;
        }
        let imageBox = document.getElementById("images-box");
        let imgLength = (selectedFiles?.length + e.target.files?.length);
        if (modalData) {
            let arr = [];
            for (const file of e.target.files) {
                arr.push(URL.createObjectURL(file));
            }
            setModalData({
                ...modalData,
                image: [
                    ...modalData.image,
                    ...arr
                ]
            })
            imgLength += modalData.image.length;
        }
        imageBox.textContent = imgLength + " ta";
        setSelectedFiles([...selectedFiles, ...e.target.files])
    }

    const removeImg = (img, i) => {
        let imagesArr = modalData?.image || preview;
        let index = imagesArr.indexOf(img);
        imagesArr.splice(index, 1);
        if (modalData.image) {
            setModalData({...modalData, image: imagesArr})
        } else {
            let a = selectedFiles;
            a.splice(i, 1);
            setSelectedFiles(a)
            setPreview([...imagesArr])
        }
        let imageBox = document.getElementById("images-box");
        if (imagesArr.length === 0) {
            imageBox.textContent = "Rasm tanlang";
        } else {
            imageBox.textContent = imagesArr.length + " ta"
        }

    }

    return (
        <AdminController pathname={props.location.pathname}>
            <div className="admin-page-universal-style">
                <div className="admin-page-head">
                    <h4 className="admin-page-head__title">Xizmatlar bo'limlari</h4>
                    <div className="admin-page-head__btn">
                        <button className="add-btn" onClick={() => toggleModal(!isOpenModal)}>Qo'shish</button>
                    </div>
                </div>
                <div className="main-body">
                    <table className="main-table">
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Bo'lim Nomi</th>
                            <th>Matni</th>
                            <th>Rasmlari</th>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            mainData?.map((category, i) =>
                                <tr>
                                    <td width="90px">{i + 1}</td>
                                    <td>{category.name}</td>
                                    <td className="services-text">
                                        {category.text}
                                    </td>
                                    <td className="images-container">
                                        {
                                            category && category?.image?.map((img, i) =>
                                                (i < 5) ? <img src={BASEURL + img} alt='hodim rasmi'/> : ""
                                            )
                                        }<span>...</span>
                                    </td>
                                    <td width="16px">
                                        <button className="edit-btn" onClick={() => edit(category)}>
                                            <EditIcon/>
                                        </button>
                                    </td>
                                    <td width="16px">
                                        <button className="delete-btn" onClick={() => toggleDeleteModal(category)}>
                                            <DeleteIcon/>
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                    <div
                        className={isOpenModal || isOpenDeleteModal ? "modal-back modal-visible" : "modal-back modal-hidden"}/>
                    <div
                        className={isOpenModal ? "save-modal-container modal-visible" : "save-modal-container modal-hidden"}>
                        <div className="save-modal big-modal">
                            <div className="save-modal-head">
                                <h4 className="save-modal-head__title">{modalData ? "O'zgartirish" : "Qo'shish"}</h4>
                                <div className="save-modal-head__close" onClick={() => toggleModal(false)}>
                                    <CloseIcon/>
                                </div>
                            </div>
                            <div className="save-modal-body">
                                <form className="form-control">
                                    <div className="save-modal-body__form">
                                        <div className="save-modal-form__left">
                                            <label htmlFor="name">
                                                Bo'lim Nomi
                                                <input type="text" defaultValue={modalData.name} name="name"
                                                       id="name"/>
                                            </label>
                                            <label htmlFor="text">
                                                Matn
                                                <textarea name="text" defaultValue={modalData.text} id="text"/>
                                            </label>
                                        </div>
                                        <div className="save-modal-form__right">
                                            <label htmlFor="images">
                                                Rasmlari
                                                <button type="button" id="images-box" className="upload-btn"
                                                        onClick={uploadFile}>Rasm tanlang</button>
                                                <input type="file" defaultValue={modalData.images} multiple
                                                       name="images"
                                                       id="images"
                                                       ref={hiddenFileInput}
                                                       onChange={onSelectFile}/>
                                            </label>
                                            <div className="save-modal-form__images">
                                                {
                                                    modalData ?
                                                        modalData.image?.map((item, i) =>
                                                            <div className="save-modal-form__image">
                                                                <img width="80px"
                                                                     src={(item + "").includes("rasm") ? BASEURL + item : item}
                                                                     alt=""/>
                                                                <span className="remove-img"
                                                                      onClick={() => removeImg(item, i)}><CloseIcon/></span>
                                                            </div>
                                                        ) :
                                                        preview?.map((item, i) =>
                                                            <div className="save-modal-form__image">
                                                                <img width="80px" src={item} alt=""/>
                                                                <span className="remove-img"
                                                                      onClick={() => removeImg(item, i)}><CloseIcon/></span>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="save-modal__foot">
                                        <button className="save-modal-body__btn" type="button"
                                                onClick={sendForm}>Saqlash
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div
                        className={isOpenDeleteModal ? "delete-modal-container modal-visible" : "delete-modal-container modal-hidden"}>
                        <div className="delete-modal">
                            <div className="delete-modal-head">
                                <h4 className="delete-modal-head__title">Ma'lumot o'chiriladi</h4>
                                <div className="delete-modal-head__close" onClick={toggleDeleteModal}>
                                    <CloseIcon/>
                                </div>
                            </div>
                            <div className="delete-modal-foot">
                                <button className="delete-modal__cancel-btn" onClick={toggleDeleteModal}>Bekor
                                    qilish
                                </button>
                                <button className="delete-modal__delete-btn" onClick={deleteOne}>O'chirish</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminController>
    );
}