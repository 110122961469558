import { ClientController } from "../../ClientController/ClientController";
import "./mainPage.scss";
import { Logo } from "../../utilities/icons";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import firstSection1 from "../../utilities/images/first-section-1.png";
import formImg2 from "../../utilities/images/form-img-1.png";
import ReactOwlCarousel from "react-owl-carousel";
import { useEffect, useState } from "react";
import FetchApi from "../../../AdminSide/Pages/Login/FetchApi";
import { BASEURL } from "../../../AdminSide/Components/Constants";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export const MainPage = (props) => {
  const [trustedUs, setTrustedUs] = useState([]);
  const getCompanies = async () => {
    return await FetchApi(BASEURL + "get_companies.php", "GET");
  };

  useEffect(() => {
    getCompanies().then((res) => {
      setTrustedUs(res && res.data);
    });
  }, []);

  const responsivePoints = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    992: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  };

  return (
    <ClientController pathname={props.location.pathname}>
      {/*      FIRST SECTION START        */}
      <div className="first-section container-fluid">
        <div className="row">
          <div
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            // data-aos-anchor-placement="top-center"
            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 first-section-left "
          >
            <div className="first-section__logo">
              <Logo width={260} height={55} />
            </div>
            <h1 className="first-section__title">DASTURIY</h1>
            <h2 className="first-section__sub-title">
              TA’MINOT ISHLAB CHIQARISH MARKAZI
            </h2>
            <p className="first-section-description">
              IMSOFT GROUP - 1000 dan ziyod kompaniyalar, tashkilotlar,
              do’konlar hamda biznesmenlar bilan hamkorlik olib boradi. Ushbu
              xizmatlar ANDROID, IOS, WEB SAYT, 1C dasturlarini o’z ichiga
              oladi. IMSOFT har qanday biznesingizni avtomatlashtiradi.
            </p>
            <a href="/" className="first-section__btn">
              Bizning xizmatlar
              <span className="first-section-btn__arrow" />
            </a>
          </div>
          <div
            data-aos="zoom-in-left"
            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 first-section-right"
          >
            <div className="first-section-img">
              <img src={firstSection1} alt="Imsoft group" />
            </div>
          </div>
        </div>
        <div className="shadow-effect effect-position-1" />
        <div className="shadow-effect effect-position-2" />
        <div className="effect-position-3" />
      </div>
      {/*      FIRST SECTION END        */}

      {/*      SERVICES START        */}
      <div
        className="services-section container-fluid"
        id="services-section_id"
      >
        <h1 data-aos="zoom-out-up" className="services-section__title">
          Bizning xizmatlar
        </h1>
        <div className="service-cards row">
          <div
            data-aos="fade-right"
            className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
          >
            <div className="service-card-1">
              <div className="service-card__icon" />
              <div className="service-card__title">Android ilovalar</div>
              {/* <div className="service-card__text">
                High-quality, reliable, secure software products only from us
              </div> */}
            </div>
          </div>
          <div
            data-aos="zoom-in"
            className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
          >
            <div className="service-card-2">
              <div className="service-card__icon" />
              <div className="service-card__title">Desktop dasturlar</div>
              {/* <div className="service-card__text">
                High-quality, reliable, secure software products only from us
              </div> */}
            </div>
          </div>
          <div
            data-aos="zoom-in-left"
            className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
          >
            <div className="service-card-3">
              <div className="service-card__icon"></div>
              <div className="service-card__title">IOS ilovalar</div>
              {/* <div className="service-card__text">
                High-quality, reliable, secure software products only from us
              </div> */}
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
          >
            <div className="service-card-4">
              <div className="service-card__icon" />
              <div className="service-card__title">Web saytlar</div>
              {/* <div className="service-card__text">
                High-quality, reliable, secure software products only from us
              </div> */}
            </div>
          </div>
          <div
            data-aos="zoom-out-down"
            className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
          >
            <div className="service-card-5">
              <div className="service-card__icon" />
              <div className="service-card__title">1C Platforma</div>
              {/* <div className="service-card__text">
                High-quality, reliable, secure software products only from us
              </div> */}
            </div>
          </div>
          <div
            data-aos="fade-up-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
          >
            <div className="service-card-6">
              <div className="service-card__icon" />
              <div className="service-card__title">Grafik dizayn</div>
              {/* <div className="service-card__text">
                High-quality, reliable, secure software products only from us
              </div> */}
            </div>
          </div>
        </div>
        <div className="shadow-effect effect-position-4" />
        <div className="shadow-effect effect-position-5" />
      </div>
      {/*      SERVICES END        */}

      {/*      TRUSTED US START        */}
      <div className="trusted-us container-fluid">
        <h1 data-aos="flip-left" className="trusted-us__title">
          Bizga ishonch bildirgan kompaniyalar
        </h1>
        <div className="trusted-us__body">
          <ReactOwlCarousel
            className="owl-theme"
            responsive={responsivePoints}
            loop
            rtlClass="lala"
            dotsEach
            autoplay
            margin={0}
          >
            {trustedUs?.map((company, i) => (
              <div className="trusted-us-card text-center" key={i}>
                <img src={BASEURL + company.logo} alt={company.title} />
              </div>
            ))}
          </ReactOwlCarousel>
        </div>
        <div className="shadow-effect effect-position-6" />
      </div>
      {/*      TRUSTED US END        */}

      {/*      FORM START        */}
      <div className="form container-fluid">
        <div className="row">
          <div
            data-aos="fade-right"
            className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12  form-left"
          >
            <h2 className="form-title">Biz bilan bog’laning</h2>
            <p className="form-description">
              Agar sizda yana savollar bo’lsa, raqamingizni formani to’ldirish
              orqali bizga qoldiring va tez orada bizning menejerlarimiz sizga
              bo’lanadi.
            </p>
            <div className="form-inputs">
              <input placeholder="F.I.SH" />
              <input placeholder="Telefon raqam" />
              <button>Yuborish</button>
            </div>
          </div>
          <div
            data-aos="fade-up-left"
            className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 form-right"
          >
            <img src={formImg2} alt="Biz bilan bog'laning" />
          </div>
        </div>
      </div>
      {/*      FORM US END        */}
    </ClientController>
  );
};

export default MainPage;
