import MainPage from "./ClientSide/pages/MainPage/MainPage";
import ClientRoute from "./ClientSide/components/ClientRoute";
import { HashRouter, Route, Switch } from "react-router-dom";
import AdminRoute from "./AdminSide/Components/AdminRoute";
import { TrustedCompanies } from "./AdminSide/Pages/TrustedCompanies/TrustedCompanies";
import { AdminServices } from "./AdminSide/Pages/Services/Services";
import Services from "./ClientSide/pages/Services/Services";
import Login from "./AdminSide/Pages/Login/Login";
import { MainSitePage } from "./AdminSide/Pages/MainSitePage/MainSitePage";
import { Projects } from "./AdminSide/Pages/Projects/Projects";
import { NotFoundPage } from "./ClientSide/pages/OtherPages/NotFoundPage";
import Contact from "./ClientSide/pages/Contact/Contact";
import Project from "./ClientSide/pages/Projects/Project";
import About from "./ClientSide/pages/AboutWe/About";
import React, { useState, useEffect } from "react";
import FetchApi from "./AdminSide/Pages/Login/FetchApi";
import { BASEURL } from "./AdminSide/Components/Constants";
import "./App.css";

function App() {
  const [ourTeamData, setOurTeamData] = useState([]);

  const getTeam = async () => {
    return await FetchApi(BASEURL + "get_team.php", "GET");
  };

  useEffect(() => {
    getTeam().then((res) => {
      setOurTeamData(res && res.data);
    });
  }, []);

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/contact" component={Contact} />
        {/*_______ Admin Routes _______*/}
        <ClientRoute
          exact
          path="/login"
          component={Login}
          ourTeamData={ourTeamData}
        />
        <AdminRoute exact path="/admin" component={MainSitePage} />
        <AdminRoute exact path="/admin/main" component={MainSitePage} />
        <AdminRoute exact path="/admin/projects" component={Projects} />
        <AdminRoute
          exact
          path="/admin/companies"
          component={TrustedCompanies}
        />
        <AdminRoute exact path="/admin/services" component={AdminServices} />

        {/*_______ Client Routes _______*/}
        <ClientRoute exact path="/services" component={Services} />
        <ClientRoute exact path="/" component={MainPage} />
        <ClientRoute
          exact
          path="/aboutUs"
          component={About}
          ourTeamData={ourTeamData}
        />
        <ClientRoute exact path="/projects" component={Project} />
        <Route component={NotFoundPage} />
        {/*<ClientRoute exact path="/comments" compon   ent={Comments}/>*/}
      </Switch>
    </HashRouter>
  );
}

export default App;
