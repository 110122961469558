import React, { useState } from "react";
import "./header.scss";
import {
  CloseIcon,
  EnvelopIcon,
  HandsetIcon,
  ImsoftLogo,
  Logo,
  MenuIcon,
} from "../../utilities/icons";
import { Link } from "react-router-dom";

export const Header = (props) => {
  const { pathname } = props;

  const headerLinks = [
    { title: "Asosiy", path: "/" },
    { title: "Xizmatlar", path: "/services" },
    { title: "Bizning Loyihalar", path: "/projects" },
    { title: "Biz haqimizda", path: "/aboutUs" },
  ];

  const [isOpenMenu, setOpenMenu] = useState(false);

  const toggleMobileMenu = () => {
    setOpenMenu(!isOpenMenu);
  };
  return (
    <>
      <div className="header container-fluid">
        <div className="row">
          <div className="header-logo col-3 col-xl-3 col-lg-3 col-md-1">
            <div className="logo-with-text">
              <Logo />
            </div>
            <div className="logo-without-text">
              <ImsoftLogo />
            </div>
          </div>
          <div className="header-navbar col-7 col-xl-7 col-lg-7 col-md-8">
            <ul>
              {headerLinks &&
                headerLinks.map((link, i) => (
                  <li key={i}>
                    <Link
                      className={
                        pathname === link.path ? "active-navbar-link" : ""
                      }
                      to={link.path}
                    >
                      {link.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="header-contact col-2 col-xl-2 col-lg-2 col-md-3">
            <div className="header-contact-tel">
              <a href="tel:+998939740550">
                <HandsetIcon />
                +998 (93) 974-05-50
              </a>
            </div>
            <div className="header-contact-email">
              <a href="mailto:info@imsoft.uz">
                <EnvelopIcon />
                info@imsoft.uz
              </a>
            </div>
          </div>
          <button
            className="col-1 header-mobile-menu-btn"
            onClick={toggleMobileMenu}
          >
            {isOpenMenu ? <CloseIcon /> : <MenuIcon />}
          </button>
          <div
            className={
              isOpenMenu
                ? "mobile-menu mobile-menu-visible"
                : "mobile-menu mobile-menu-hidden"
            }
          >
            <ul>
              {headerLinks &&
                headerLinks.map((link, i) => (
                  <li key={i}>
                    <Link
                      className={
                        pathname === link.path ? "active-navbar-link" : ""
                      }
                      to={link.path}
                    >
                      {link.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
