import React from "react";
import {
  EnvelopIcon,
  FaceBookIcon,
  HandsetIcon,
  InstagramIcon,
  Logo,
  TelegramIcon,
} from "../../utilities/icons";
import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer container-fluid">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="footer-logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="footer-description">
            Biz IMSOFT GROUP qariyb 24 yil davomida turli xildagi ishlab
            chiqarish korxonalari, xizmat ko‘rsatish servislarini, og‘irini
            yengil, qiyinini oson qilib kelmoqdamiz!
          </div>
          <div className="footer-copyright">Copyright © IMSOFT GROUP 2021</div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="row footer-text">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 footer-information">
              <h5 className="footer-information-title">Ma`lumot</h5>
              <Link to="/">Bosh sahifa</Link>
              <Link to="/aboutUs">Biz haqimizda</Link>
              <Link to="/services">Bizning hizmatlar</Link>
              <Link to="/contact">Biz bilan aloqa</Link>
              <Link to="/">Guvohnomalar</Link>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 footer-contact">
              <h5 className="footer-contact-title">Biz bilan aloqa</h5>
              <div className="footer-contact-tel">
                <a href="tel:+998939740550">
                  <HandsetIcon />
                  +998 (93) 974-05-50
                </a>
              </div>
              <div className="footer-contact-email">
                <a href="mailto:info@imsoft.uz">
                  <EnvelopIcon />
                  info@imsoft.uz
                </a>
              </div>
              <div className="footer-social-nets">
                <a href="https://t.me/imsoftuz">
                  <TelegramIcon />
                </a>
                <a href="https://www.instagram.com/imsoft.uz/">
                  <InstagramIcon />
                </a>
                <a href="https://en-gb.facebook.com/imsoft.uz">
                  <FaceBookIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright-mobile d-none">
        Copyright © IMSOFT GROUP 2021
      </div>
    </div>
  );
};
export default Footer;
