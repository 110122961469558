import axios from "axios";

export default async (url, method, data = null) => {
    try {
        return await axios({
            url: url,
            method: method,
            data: data,
        });
    } catch (error) {
        console.log("error => ", error)
    }
};
