import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

export const ClientController = ({pathname, children}) => {
    return (
        <>
            <Header pathname={pathname}/>
                {children}
            <Footer/>
        </>
    )
}