import React from "react";
import FetchApi from "./FetchApi";
import "./login.scss";
import { BASEURL } from "../../Components/Constants";

const Login = () => {
  const sendLogin = async (e) => {
    let login = document.getElementById("login")?.value;
    let password = document.getElementById("password")?.value;

    await FetchApi(
      BASEURL + "in_login.php",
      "POST",
      JSON.stringify({
        login: login,
        password: password,
      })
    ).then((res) => {
      if (res && res.data && res.data.length) {
        localStorage.setItem("user", JSON.stringify(res.data));
        window.location.href = "/#/admin";
      } else {
        let errorMessage = document.getElementsByClassName(
          "login__error-message"
        );
        if (errorMessage) {
          errorMessage[0].classList.add("login__error-message-visible");
          setTimeout(() => {
            errorMessage[0].classList.remove("login__error-message-visible");
          }, 2500);
        }
      }
    });
  };

  return (
    <div className="login-background">
      <div className="login-container">
        <h2 className="login-title">Kirish</h2>
        <div className="login__error-message">
          Login yoki parol mos kelmadi!
        </div>
        <div className="login-form">
          <label htmlFor="login" className="py-2">
            Login
          </label>
          <input
            className="form-control thirdInput login-input"
            type="text"
            name="login"
            id="login"
            autoComplete="off"
            placeholder="Login"
            required
          />
          <label htmlFor="pwd" className="py-2">
            Parol
          </label>
          <input
            className="form-control thirdInput login-input"
            type="password"
            name="password"
            id="password"
            autoComplete="off"
            placeholder="Parol"
            required
          />
          <button className="btn btn-primary w-100" onClick={sendLogin}>
            Kirish
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
