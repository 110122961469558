import React from 'react';
import {Link, useHistory} from "react-router-dom";
import "./notFoundPage.scss";
import backImg from "../../utilities/images/left-arrow.svg";
import homepageImg from "../../utilities/images/homepage-icon.svg";

export const NotFoundPage = () => {

    let history = useHistory();

    return (
        <div className="not-found-page d-flex justify-content-center">
            <div className="ball1"/>
            <div className="ball2"/>
            <div className="ntf-modal">
                <h1>Saytimizda bunday sahifa topilmadi <sup><h4 className="d-inline-block">404</h4></sup></h1>
                <div className="ntf-modal-foot">
                    <button className="ntf-page-btn-to-back" onClick={() => history.goBack()}>
                        <img src={backImg} alt="<-"/>Orqaga
                    </button>

                    <Link className="ntf-link" to="/">
                        <button className="ntf-page-btn-to-main">
                            Asosiy sahifaga
                            <img src={homepageImg} alt=""/>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}