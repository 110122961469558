import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export default ({component: Component, ...rest}) => {
    window.onhashchange = null;
    const isSigned = JSON.parse(localStorage.getItem("user"));
    return <Route {...rest} render={props =>
        isSigned && isSigned[0]?.name ? <Component {...props}/>
            : <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
    }/>
};