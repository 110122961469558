import React from 'react';
import {ArrowLittleIcon} from "./icons/icons";
import {Link, NavLink} from "react-router-dom";

export default (props) => {
    const {activeMenuLinkIndex, path, localPath, setActive, icon, title, amount, index} = props;
    return (
        <NavLink to={"/admin" + path}
                 className={"/admin" + path === localPath ? "new-link active-new-link" : "new-link not-active-new-link"}>
            <div>
                <span className={icon ? "icon " + icon : ""}/>{title}
            </div>
            <div>
                {/*{amount && amount > 0 ? <span className="new-link__amount">{amount}</span> : ""}*/}
            </div>
        </NavLink>
    );
}
