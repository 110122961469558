import React from 'react';
import "./adminController.scss";
import "./universal.scss";
import MenuLink from "../Components/MenuLink";
// import mainLinks from "./mainLinks";
// import SmallMenuLink from "../components/SmallSubMenu";
// import SubMenuLink from "../components/SubMenuLink";
import profilAvatar from "../Components/images/img.png";
import {Logo} from "../../ClientSide/utilities/icons";
import mainLinks from "./mainLinks";

export const AdminController = (props) => {

    return (
        <div className="admin-layout">

            <div className="admin-header">
                <div/>
                <div className="admin-header-user">
                    {/*<BellIcon/>*/}
                    <div className="admin-header-user-avatar"
                         style={{backgroundImage: `url("${profilAvatar}")`}}/>
                    Admin
                </div>
            </div>

            <div className="admin-sidebar">
                <div className="admin-sidebar__head">
                    <Logo/>
                </div>
                <div className="admin-sidebar__body">
                    <div className="admin-sidebar__menu">
                        {
                            mainLinks && mainLinks.map((link, i) =>
                                <div className={`admin-sidebar__menu__big${i}`} key={i}>
                                    <MenuLink title={link.title}
                                              index={i}
                                              path={link.path}
                                              localPath={props.pathname}
                                              icon={link.icon}
                                              amount={link.amount || ""}/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>


            {/*Main Body*/}
            <div className="admin-main-body">
                {props.children}
            </div>

        </div>
    );
}