import React, { useEffect, useState } from "react";
import { AdminController } from "../../AdminController/AdminController";
import { CloseIcon, DeleteIcon, EditIcon } from "../../Components/icons/icons";
import FetchApi from "../Login/FetchApi";
import { BASEURL } from "../../Components/Constants";

export const Projects = (props) => {
  const [mainData, setMainData] = useState([]);
  const [isOpenModal, setModal] = useState(false);
  const [isOpenDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [modalData, setModalData] = useState(false);

  console.log(mainData);
  const getAllData = async () => {
    return await FetchApi(BASEURL + "get_projects.php", "GET");
  };

  useEffect(() => {
    getAllData().then((r) => {
      setMainData(r && r.data);
    });
  }, [modalData, deleteItem]);

  const deleteOne = async () => {
    toggleDeleteModal(null);
    let obj = {
      Id: deleteItem.Id,
    };
    let res = await FetchApi(BASEURL + "del_projects.php", "POST", obj);
    await getAllData();
  };
  const toggleDeleteModal = (item) => {
    setDeleteItem(item);
    setDeleteModal(!isOpenDeleteModal);
  };
  const edit = (item) => {
    setModal(true);
    setModalData(item);
    let el = document.getElementById("title");
    let el2 = document.getElementById("description");
    let el3 = document.getElementById("about");
    if (el) {
      el.value = item.title;
    }
    if (el2) {
      el2.value = item.description;
    }
    if (el3) {
      el3.value = item.about;
    }
  };
  const toggleModal = (bool) => {
    setModalData(false);
    setModal(bool);
    let el = document.getElementById("title");
    let el2 = document.getElementById("description");
    let el3 = document.getElementById("about");
    if (!modalData) {
      if (el) {
        el.value = null;
      }
      if (el) {
        el2.value = null;
        el3.value = null;
      }
    }
  };
  const sendForm = async () => {
    let title = document.getElementById("title");
    let image = document.getElementById("images");
    let text = document.getElementById("description");
    let aboutWe = document.getElementById("about");

    if (title.value !== "") {
      let imageName = await sendImage(image.files[0]);
      let obj = {
        title: title && title.value,
        image: imageName,
        description: text && text.value,
        about: aboutWe && aboutWe.value,
      };
      toggleModal(false);
      if (modalData) {
        obj = { ...obj, Id: modalData.Id };
      }
      let response = await FetchApi(
        BASEURL + "add_projects.php",
        modalData ? "PUT" : "POST",
        obj
      );
      await getAllData().then((r) => {
        setMainData(r && r.data);
      });
    }
  };
  const sendImage = async (imgFile) => {
    try {
      let formData = new FormData();
      formData.append("image", imgFile);
      return await FetchApi(
        BASEURL + "insert_image.php",
        "POST",
        formData
      ).then((res) => {
        return res.data;
      });
    } catch (err) {}
  };

  return (
    <AdminController pathname={props.location.pathname}>
      <div className="admin-page-universal-style">
        <div className="admin-page-head">
          <h4 className="admin-page-head__title">Biz qilgan loyihalar</h4>
          <div className="admin-page-head__btn">
            <button
              className="add-btn"
              onClick={() => toggleModal(!isOpenModal)}
            >
              Qo'shish
            </button>
          </div>
        </div>
        <div className="main-body">
          <table className="main-table">
            <thead>
              <tr>
                <th>№</th>
                <th>Nomi</th>
                <th>Sarlavha</th>
                <th>Text</th>
                <th>Rasm</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {mainData?.map((project, i) => (
                <tr>
                  <td width="90px">{i + 1}</td>
                  <td>{project.title}</td>
                  <td>{project.description}</td>
                  <div
                    style={{
                      width: 200,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    <td>{project.about}</td>
                  </div>
                  <td>
                    <img src={BASEURL + project.image} alt="hodim rasmi" />
                  </td>
                  <td width="16px">
                    <button className="edit-btn" onClick={() => edit(project)}>
                      <EditIcon />
                    </button>
                  </td>
                  <td width="16px">
                    <button
                      className="delete-btn"
                      onClick={() => toggleDeleteModal(project)}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className={
              isOpenModal || isOpenDeleteModal
                ? "modal-back modal-visible"
                : "modal-back modal-hidden"
            }
          />
          <div
            className={
              isOpenModal
                ? "save-modal-container modal-visible"
                : "save-modal-container modal-hidden"
            }
          >
            <div className="save-modal">
              <div className="save-modal-head">
                <h4 className="save-modal-head__title">
                  {modalData ? "O'zgartirish" : "Qo'shish"}
                </h4>
                <div
                  className="save-modal-head__close"
                  onClick={() => toggleModal(false)}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="save-modal-body">
                <form className="form-control">
                  <label htmlFor="title">
                    Nomi
                    <input
                      type="text"
                      defaultValue={modalData.title}
                      required
                      name="title"
                      id="title"
                    />
                  </label>
                  <label htmlFor="images">
                    Rasmlari
                    <input
                      type="file"
                      defaultValue={modalData.images}
                      required
                      multiple
                      name="images"
                      id="images"
                    />
                  </label>{" "}
                  <label htmlFor="description">
                    Sarlavha
                    <input
                      name="description"
                      defaultValue={modalData.description}
                      id="description"
                    />
                  </label>
                  <label htmlFor="about">
                    Matn
                    <textarea
                      name="about"
                      defaultValue={modalData.about}
                      id="about"
                    />
                  </label>
                  <button
                    className="save-modal-body__btn"
                    type="button"
                    onClick={sendForm}
                  >
                    Saqlash
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div
            className={
              isOpenDeleteModal
                ? "delete-modal-container modal-visible"
                : "delete-modal-container modal-hidden"
            }
          >
            <div className="delete-modal">
              <div className="delete-modal-head">
                <h4 className="delete-modal-head__title">
                  Ma'lumot o'chiriladi
                </h4>
                <div
                  className="delete-modal-head__close"
                  onClick={toggleDeleteModal}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="delete-modal-foot">
                <button
                  className="delete-modal__cancel-btn"
                  onClick={toggleDeleteModal}
                >
                  Bekor qilish
                </button>
                <button
                  className="delete-modal__delete-btn"
                  onClick={deleteOne}
                >
                  O'chirish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminController>
  );
};
