import React, { useState, useEffect } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../utilities/icons";
import FetchApi from "../../../AdminSide/Pages/Login/FetchApi";
import { BASEURL } from "../../../AdminSide/Components/Constants";
import { ClientController } from "../../ClientController/ClientController";
import Modal from "react-modal";
import "./Project.css";

const customStyles = {
  content: {
    position: "absolute",
    inset: "50% auto auto 50%",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    borderRadius: "15px",
    outline: "none",
    padding: "20px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "510px",
    transition: "all 3s ease 0s",
    width: "60rem",
  },
};
// import "./Project.css";

const Project = (props) => {
  const [projects, setProjects] = useState([]);
  const [clickClount, setClickCount] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [a, setA] = useState(null);
  const getProjects = async () => {
    return await FetchApi(BASEURL + "get_projects.php", "GET");
  };

  useEffect(() => {
    getProjects().then((res) => {
      setProjects(res && res.data);
    });
    setA(document.getElementById("a"));
  }, []);

  const prevCarouselItem = () => {
    if (clickClount !== 0) {
      let firstCard = document.getElementsByClassName("project-card");
      if (clickClount <= firstCard.length - 3) {
        setClickCount(clickClount - 1);

        for (let i = 0; i < firstCard.length; i++) {
          // console.log(firstCard[i].)
          console.log(firstCard[i]);
          let a = firstCard[i].style.transform.substring(
            10,
            firstCard[i].style.transform.length - 3
          );
          firstCard[i].style.transform = `translate(${+a + 339}px)`;
        }
      }
    }
  };
  const nextCarouselItem = () => {
    let firstCard = document.getElementsByClassName("project-card");
    if (clickClount < firstCard.length - 3) {
      setClickCount(clickClount + 1);
      for (let i = 0; i < firstCard.length; i++) {
        // console.log(firstCard[i].)
        console.log(firstCard[i]);
        let a = firstCard[i].style.transform.substring(
          10,
          firstCard[i].style.transform.length - 3
        );
        firstCard[i].style.transform = `translate(${+a - 339}px)`;
      }
    }
  };
  const isActiveNextBtn = () => {
    let firstCard = document.getElementsByClassName("project-card");
    if (clickClount === 0 || clickClount < firstCard.length - 3) {
      return "projects-head__enabled-btn";
    } else {
      return "projects-head__disabled-btn";
    }
  };

  let subtitle;

  function openModal(project) {
    setModalData(project);
    drawAbout(project.about);
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // function drawAbout(str) {
  //   let d = "&lt;p&gt;";
  //   let a = d?.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
  //     return String.fromCharCode(charCode);
  //     // console.log(String.fromCharCode(charCode));
  //   });
  //   console.log(a);
  // }

  function drawAbout(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString("&lt;", "text/html");
    return doc.body;
  }

  // function drawAbout(text) {
  //   console.log(a);
  //   console.log(document.getElementById("a"));
  //   if (a) {
  //     a.innerHTML = text;
  //   }
  // }

  return (
    <ClientController pathname={props.location.pathname}>
      <div>
        <div className="projects-section container-fluid">
          <div className="projects-head">
            <h1 data-aos="fade-right" className="projects-head__title">
              Biz qilgan loyihalar
            </h1>
            <div data-aos="fade-left" className="projects-head__btns">
              <button
                className={
                  clickClount !== 0
                    ? "projects-head__enabled-btn"
                    : "projects-head__disabled-btn"
                }
                onClick={() => prevCarouselItem()}
              >
                <ArrowLeftIcon />
              </button>
              <button
                className={isActiveNextBtn()}
                onClick={() => nextCarouselItem()}
              >
                <ArrowRightIcon />
              </button>
            </div>
          </div>
          <div className="projects-container">
            <div
              data-aos="zoom-in"
              data-aos-duration="1500"
              className="projects-slider"
            >
              {projects?.map((project) => (
                <div className="project-card">
                  <div className="project-card-img">
                    <img src={BASEURL + project.image} alt="Bizning loyiha" />
                  </div>
                  <div className="project-card-info">
                    <div className="project-card-title">{project.title}</div>
                    <div className="project-card-text">
                      {project.description}
                    </div>
                    <button
                      className="project-card-button"
                      onClick={() => openModal(project)}
                    >
                      Batafsil
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="about-head">
                <h4 className="modal-title">{modalData.description}</h4>

                <i
                  className="fa-solid fa-rectangle-xmark"
                  onClick={closeModal}
                ></i>
              </div>
              <div style={{ display: "flex", marginTop: "19px" }}>
                <img
                  src={`https://imsoft.uz/api/${modalData.image}`}
                  className="modal-img"
                  alt=""
                />
                <div style={{ padding: "16px" }}>
                  <h4
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="modal-description"
                  >
                    {modalData.title}
                  </h4>
                  <div className="modal-scroll">
                    <p id="a" className="modal-about">
                      {modalData.about}
                      {/* {drawAbout(modalData.about)} */}
                    </p>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </ClientController>
  );
};

export default Project;
